html {
  min-height: 100vh;
}

body {
  background: #eee;
}

body, #root, #root > div {
  /* 68 (height menu + 11 margin bottom) */
  min-height: calc(100vh - 79px);
}

.primary-color-merchant {
  font-weight: bold;
  color: #270570;
}

.wizard-mobile-wrapper {
  height: 100%;
  background: #eee;
  overflow: hidden;
  overflow-y: scroll;
}

@media(max-width: 959px) {
  html, body {
    overflow: hidden;
  }
}